body {
  --grey: #e0e0e0;
  --darkGrey: #c6c4c5;
  --reddish: #ff6b50;
  --offWhite: #f5f5f5;
  --processdelightBlue: #124464;
  /* TODO: update these based on personalization settings */
  --webpart-background-color: #fff7ec;
  --webpart-border-radius: 0.625rem;
  --content-page-background-color: #fffcf5;
}

/* You can add global styles to this file, and also import other style files */
.scrollbar {
  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
    background-color: var(--offWhite);
    border-radius: 10px;
  }

  &::-webkit-scrollbar {
    width: 6px;
    background-color: var(--offWhite);
    margin: 15px 0;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(201, 203, 207, 1);
    background-color: var(--processdelightBlue);
  }
}
.horizontal-scrollbar {
  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
    background-color: var(--offWhite);
    border-radius: 10px;
  }

  &::-webkit-scrollbar {
    height: 6px;
    background-color: var(--offWhite);
    margin: 15px 0;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(201, 203, 207, 1);
    background-color: var(--processdelightBlue);
  }
}

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: "Open Sans", sans-serif !important;
}
.clickable {
  cursor: pointer !important;
}
.nav-tabs {
  border-bottom: 0 !important;
}
.nav-link {
  color: var(--processdelightBlue) !important;
  border-bottom: 1px solid;
  border-color: rgb(227, 227, 228, 0.6) !important;
  &:hover {
    background-color: rgb(227, 227, 228, 0.4) !important;
  }
  &.active {
    background-color: rgb(227, 227, 228, 0.4) !important;
    border-bottom: 0 !important;
  }
}
.mat-mdc-chip-listbox {
  &[aria-orientation="vertical"] {
    .mdc-evolution-chip-set__chips {
      display: flex;
      flex-direction: column;
    }
  }
}

.w-fit-content {
  width: fit-content;
}

.mat-mdc-chip {
  background-color: white !important;
  border: 1px solid rgb(227, 227, 228, 1);
  &.active {
    background-color: #618baa !important;
    .mat-mdc-chip-action-label {
      color: white !important;
    }
  }
  .mdc-evolution-chip__cell {
    justify-content: center;
  }
  &:not(.disabled) .mdc-evolution-chip__cell > * {
    cursor: pointer;
  }
}
.mat-mdc-menu-content,
.mat-mdc-select-panel {
  padding: 0 !important;
}
.mat-mdc-option {
  padding: 0 0.5rem !important;
}
.mat-mdc-select-panel-above {
  width: fit-content !important;
}
.mat-mdc-form-field {
  width: 100%;
}
body .border {
  &-warn {
    border-color: var(--reddish) !important;
  }
  &-gray {
    border-color: var(--grey) !important;
  }
  &-dotted {
    border-style: dotted !important;
  }
  &-dashed {
    border-style: dashed !important;
  }
  &-width {
    &-1 {
      border-width: 1px !important;
    }
    &-2 {
      border-width: 2px !important;
    }
    &-3 {
      border-width: 3px !important;
    }
    &-4 {
      border-width: 4px !important;
    }
    &-5 {
      border-width: 5px !important;
    }
  }
}
.h-fit-content {
  height: fit-content;
}
.w-0 {
  width: 0;
}
// To counteract global styling
.mat-mdc-form-field .mat-mdc-form-field-subscript-wrapper {
  display: block !important;
}
.min-height-0 {
  min-height: 0;
}
.min-width-0 {
  min-width: 0;
}
.mat-mdc-form-field.no-subscript-wrapper,
.no-subscript-wrapper .mat-mdc-form-field {
  .mat-mdc-form-field-subscript-wrapper {
    height: 0;
  }
}
.overflow-x-auto {
  overflow-x: auto;
}
.overflow-y-auto {
  overflow-y: auto;
}
.overflow-y-hidden {
  overflow-y: hidden;
}
.overflow-x-hidden {
  overflow-x: hidden;
}

.mat-mdc-icon-button {
  line-height: 24px;
}
.mat-mdc-snack-bar-container {
  --mat-mdc-snack-bar-button-color: #ffffff !important;
  --mdc-snackbar-supporting-text-color: #ffffff;
  &.app-notification-error {
    --mdc-snackbar-container-color: #ff6b50;
  }
  &.app-notification-success {
    --mdc-snackbar-container-color: #64cd64;
  }
}

.lay-over {
  z-index: 999 !important;
}
.mat-mdc-dialog-container .mdc-dialog__surface {
  overflow: hidden;
}
.bold {
  font-weight: bold;
}
.italic {
  font-style: italic;
}
mat-tab-group .mat-mdc-tab-body-wrapper {
  height: 100% !important;
}
